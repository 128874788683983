const vidbg = require('vidbg.js')

import '@fortawesome/fontawesome-free/css/all.css'


var instance = new vidbg(
  'body',
  {
    mp4: "videos/weekend/weekend-banner.mp4", // URL or relative path to MP4 video
    poster: "img/heros/weekend-hero.jpg", // URL or relative path to fallback image
  }
);